import React from "react";
import styled from "@emotion/styled";
import Section, { Heading3, RichText } from "./elements/Section";
import PropTypes from "prop-types";
import {
	spacingRestricted,
	gridColumnByMargin,
	container,
	screen,
	grid,
} from "../styles/mixins";
import color, { themeColors } from "../styles/colors";
import { darken, transparentize } from "polished";
import Video from "./Video";

const SectionEl = styled(Section)`
	${spacingRestricted("padding-top", "l")};
	${spacingRestricted("padding-bottom", "l")};

	&:last-child {
		${spacingRestricted("padding-bottom", "xxl")};
	}
`;

const ContainerEl = styled.div`
	${container()};
`;

const Article = styled.div`
	${gridColumnByMargin(2)}
	display: flex;
	flex-direction: column;
	${spacingRestricted("gap", "xxs")}
	align-items: stretch;

	@media ${screen("md")} {
		${gridColumnByMargin(4)}
		flex-direction: row;
		align-items: flex-end;
	}

	@media ${screen("lg")} {
		${gridColumnByMargin(8)}
	}
`;

const GuidesArticleVideo = ({ className, video, colorTheme = "light" }) => {
	return (
		<SectionEl colorTheme={colorTheme}>
			<ContainerEl>
				<Article className={className} colorTheme={colorTheme}>
					<Video url={video.guideContentVideo} />
				</Article>
			</ContainerEl>
		</SectionEl>
	);
};

GuidesArticleVideo.propTypes = {
	colorTheme: PropTypes.string,
};

GuidesArticleVideo.defaultProps = {
	colorTheme: "light",
};

export default GuidesArticleVideo;
