import React from "react";
import styled from "@emotion/styled";
import Section, { Heading3, RichText } from "./elements/Section";
import PropTypes from "prop-types";
import {
	spacingRestricted,
	gridColumnByMargin,
	container,
	screen,
	grid,
} from "../styles/mixins";
import color, { themeColors } from "../styles/colors";
import { darken, transparentize } from "polished";
import LinkTo from "./elements/LinkTo";
import { ReactComponent as Download } from "../images/vectors/download-icon.svg";
import { button } from "../styles/typography";

const SectionEl = styled(Section)`
	${spacingRestricted("padding-top", "l")};
	${spacingRestricted("padding-bottom", "l")};

	&:last-child {
		${spacingRestricted("padding-bottom", "xxl")};
	}
`;

const ContainerEl = styled.div`
	${container()};
`;

const Article = styled.div`
	background-color: ${(props) =>
		transparentize(0.97, themeColors[props.colorTheme].fg)};
	border-radius: 15px;
	${grid(46)};
	${gridColumnByMargin(2)};
	${spacingRestricted("padding-top", "m")};
	${spacingRestricted("padding-bottom", "m")};
	@media ${screen("md")} {
		${grid(36)};
		${gridColumnByMargin(6)}
	}
	@media ${screen("lg")} {
		${grid(24)};
		${gridColumnByMargin(12)}
	}
`;

const Inner = styled.div`
	${gridColumnByMargin(3)};
	@media ${screen("md")} {
		${gridColumnByMargin(2, 36)};
	}
	@media ${screen("lg")} {
		${gridColumnByMargin(2, 24)};
	}
`;

const Heading = styled(Heading3)``;

const Icon = styled.span`
	display: inline-block;
	width: 34px;
	height: 34px;
	background-color: ${color("orange")};
	border-radius: 100px;
	text-align: center;
	margin-right: 0.5em;
`;

const DownloadIcon = styled(Download)`
	margin: 0.15em auto 0;
	line-height: 100%;
`;

const Label = styled.span`
	${button};
	color: ${color("orange")};
	font-weight: 600;
`;

const Button = styled(LinkTo)`
	display: inline-flex;
	align-items: center;
	${spacingRestricted("margin-top", "xs")};
	&:hover {
		${Icon} {
			background-color: ${darken(0.05, color("orange"))};
		}
		${Label} {
			color: ${darken(0.05, color("orange"))};
		}
	}
`;

const GuidesArticleDownload = ({
	className,
	download,
	colorTheme = "light",
}) => {
	return (
		<SectionEl colorTheme={colorTheme}>
			<ContainerEl>
				<Article className={className} colorTheme={colorTheme}>
					<Inner>
						<Heading>{download.downloadTitle}</Heading>
						<Button url={download.downloadAsset[0].url}>
							<Icon>
								<DownloadIcon />
							</Icon>
							<Label>DOWNLOAD</Label>
						</Button>
					</Inner>
				</Article>
			</ContainerEl>
		</SectionEl>
	);
};

GuidesArticleDownload.propTypes = {
	colorTheme: PropTypes.string,
};

GuidesArticleDownload.defaultProps = {
	colorTheme: "light",
};

export default GuidesArticleDownload;
