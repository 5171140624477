import React, { useCallback, useEffect, useRef, useState } from "react";
import "intersection-observer";
import Player from "@vimeo/player";
import { ReactComponent as PlayIcon } from "../images/vectors/play-icon.svg";
import styled from "@emotion/styled";
import color from "../styles/colors";

const VideoEl = styled.div`
	grid-column: 1 / -1;
	display: flex;
	flex-direction: row;
	height: 100%;
	width: 100%;
	padding-bottom: 56.25%;
	position: relative;
	cursor: pointer;
`;

const PlayButton = styled.div`
	position: absolute;
	width: 67px;
	height: 46px;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	margin: auto;
	pointer-events: none;
	border-radius: 6px;
	background: ${color("orange")};
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 10;
`;

const VideoContainer = styled.div`
	align-items: center;
	display: flex;
	flex-grow: 1;
	position: absolute;
	width: 100%;
	height: 100%;
`;

const IframeWrapper = styled.div`
	position: relative;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 10;
	display: flex;
	align-items: center;
`;

const Iframe = styled.iframe`
	position: absolute;
	left: 0;
	width: 100%;
	height: 100%;
	pointer-events: ${(props) => (props.isPlaying ? "initial" : "none")};
`;

const Video = ({ url = "https://vimeo.com/14352658" }) => {
	const player = useRef(null);
	const [videoPlaying, setVideoPlaying] = useState(false);

	function GetVimeoIDbyUrl(url) {
		return url.match(
			/(http|https)?:\/\/(www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|)(\d+)(?:|\/\?)/
		)[4];
	}

	const handleControl = () => {
		setVideoPlaying(true);
	};

	const videoId = GetVimeoIDbyUrl(url);
	const [videoUrl, setVideoUrl] = useState(
		`https://player.vimeo.com/video/${videoId}?controls=0")`
	);

	useEffect(() => {
		setVideoUrl(
			`https://player.vimeo.com/video/${videoId}?controls=${
				videoPlaying ? "1&autoplay=true" : 0
			}")`
		);
		if (videoPlaying) {
			setTimeout(() => {
				player.current.play();
			}, 1000);
		}
	}, [videoPlaying]);

	// JSX references
	const iframeWrapper = useRef(null);
	const video = useRef(null);

	useEffect(() => {
		player.current = new Player(video.current);
	}, []);

	return (
		<VideoEl onClick={handleControl}>
			<VideoContainer>
				<IframeWrapper ref={iframeWrapper}>
					<Iframe
						src={videoUrl}
						frameBorder="0"
						allow="autoplay; fullscreen"
						allowFullScreen
						ref={video}
						isPlaying={videoPlaying}></Iframe>
				</IframeWrapper>
			</VideoContainer>
			{!videoPlaying && (
				<PlayButton>
					<PlayIcon />
				</PlayButton>
			)}
		</VideoEl>
	);
};

export default Video;
