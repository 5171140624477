import React from "react";
import { format } from "date-fns";
import { graphql } from "gatsby";
import SEO from "../components/seo";
import HeroGuides from "../components/HeroGuides";
import Layout from "../components/layout";
import GuidesArticleText from "../components/GuidesArticleText";
import GuidesArticleImages from "../components/GuidesArticleImages";
import GuidesArticleDownload from "../components/GuidesArticleDownload";
import Video from "../components/Video";
import GuidesArticleVideo from "../components/GuidesArticleVideo";

export default function GuidesSingle({ data }) {
	const {
		metaTitle,
		metaDescription,
		metaShareImage,
		heroImage,
		heroImageMobile,
		heroTitle,
		heroText,
		guideAuthor,
		guideContent,
		dateCreated,
	} = data?.craft.entry ?? [];

	let dateString = format(new Date(dateCreated), "d MMMM yyyy");

	let content = guideContent.map((item) => {
		if (item.guideContentText) {
			return <GuidesArticleText content={item.guideContentText} />;
		}

		if (item.guideContentImage) {
			return <GuidesArticleImages images={item.guideContentImage} />;
		}

		if (item.downloadAsset) {
			return <GuidesArticleDownload download={item} />;
		}

		if (item.guideContentVideo) {
			return <GuidesArticleVideo video={item} />;
		}
	});

	return (
		<Layout className="layout--guidesSingle">
			<SEO
				title={metaTitle}
				description={metaDescription}
				image={metaShareImage}
			/>

			<HeroGuides
				title={heroTitle}
				text={heroText}
				date={dateString}
				author={guideAuthor}
				image={heroImage}
				mobileImage={heroImageMobile}
				colorTheme="light"
			/>
			{content.map((item) => item)}
		</Layout>
	);
}

export const pageQuery = graphql`
	query guides($id: CraftCMS_QueryArgument!) {
		craft {
			entry(id: [$id]) {
				id
				... on CraftCMS_guidesInsights_guides_Entry {
					id
					uri
					slug
					dateCreated
					metaTitle
					metaDescription
					metaShareImage {
						width
						height
						url
						filename
					}
					heroTitle
					heroText
					heroImage {
						width
						height
						url
						title
						imagerX(transform: "maxWidth-1920") {
							width
							height
							url
						}
						imagerXWp: imagerX(transform: "maxWidth-1920-wp") {
							width
							height
							url
						}
					}
					heroImageMobile {
						width
						height
						url
						imagerX(transform: "maxWidth-1920") {
							width
							height
							url
						}
						imagerXWp: imagerX(transform: "maxWidth-1920-wp") {
							width
							height
							url
						}
					}
					guideAuthor
					guideContent {
						... on CraftCMS_guideContent_guideContentText_BlockType {
							guideContentText
						}
						... on CraftCMS_guideContent_guideContentVideo_BlockType {
							guideContentVideo
						}
						... on CraftCMS_guideContent_guideContentImage_BlockType {
							guideContentImage {
								filename
								height
								url
								width
								title
								imagerX(transform: "maxWidth-1920") {
									width
									height
									url
								}
								imagerXWp: imagerX(transform: "maxWidth-1920-wp") {
									width
									height
									url
								}
							}
						}
						... on CraftCMS_guideContent_guideContentDownload_BlockType {
							downloadTitle
							downloadAsset {
								... on CraftCMS_resources_Asset {
									title
									url
								}
							}
						}
					}
				}
			}
		}
	}
`;
